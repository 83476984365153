import { environment } from '@accredible-frontend-v2/envs';
import { HttpClient } from '@angular/common/http';
import { Injectable, ModuleWithProviders, NgModule } from '@angular/core';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import {
  Translation,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  translocoConfig,
  TranslocoLoader,
  TranslocoModule,
} from '@ngneat/transloco';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { Observable } from 'rxjs';
import { AccredibleLanguageService } from './language.service';
import { getAvailableLanguages } from './languages';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(languageCode: string): Observable<{ [key: string]: string }> {
    return this.http.get<Translation>(`/assets/i18n/${languageCode}.json`);
  }
}

@NgModule({
  imports: [TranslocoMessageFormatModule.forRoot()],
  exports: [TranslocoModule],
  // Date Adapter needs to be provided as default for apps
  // (e.g.: credential-renderer) that do not provide a custom one
  providers: [{ provide: DateAdapter, useClass: NativeDateAdapter }],
})
export class AccredibleLanguageServiceModule {
  static forRoot(
    supportedLanguageCodes?: string[],
  ): ModuleWithProviders<AccredibleLanguageServiceModule> {
    return {
      ngModule: AccredibleLanguageServiceModule,
      providers: [
        AccredibleLanguageService,
        {
          provide: TRANSLOCO_CONFIG,
          useValue: translocoConfig({
            availableLangs: getAvailableLanguages(supportedLanguageCodes).map((lang) => {
              return lang.code;
            }),
            defaultLang: 'en',
            fallbackLang: 'en',
            // Remove this option if your application
            // doesn't support changing language in runtime.
            reRenderOnLangChange: true,
            prodMode: environment.type === 'production',
          }),
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
      ],
    };
  }
}

import { AccredibleBrowserStorageService } from '@accredible-frontend-v2/services/browser-storage';
import {
  AccredibleResponsiveBreakpoint,
  AccredibleResponsiveHelper,
} from '@accredible-frontend-v2/utils/responsive-helper';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

const COOKIES_ACCEPTED = 'cookiesAccepted';

@Component({
  selector: 'accredible-cookies-banner',
  templateUrl: './cookies-banner.component.html',
  styleUrls: [`./cookies-banner.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesBannerComponent implements OnInit, AfterViewInit, OnDestroy {
  // On Recipient Portal we want to put the cookie banner on the top of the page in mobile view because
  // in the credential view there's a share drawer on the bottom of the page.
  @Input()
  isRecipientPortal = false;

  @Output()
  cookiesDismissed = new EventEmitter();

  @ViewChild('cookiesBanner')
  cookiesBanner: ElementRef<HTMLElement>;

  showCookiesBanner = false;
  // Check for accent color variable, this is only present on the material themed apps
  hasMatTheme = !!getComputedStyle(document.body).getPropertyValue('--accent-color');

  private _matSideNavContentEl: HTMLElement;

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _browserStorage: AccredibleBrowserStorageService,
  ) {}

  ngOnInit(): void {
    const cookiesAccepted = this._browserStorage.get(COOKIES_ACCEPTED);
    this.showCookiesBanner = cookiesAccepted !== 'true';
  }

  ngAfterViewInit(): void {
    if (this.showCookiesBanner) {
      // Making sure the element has been fully rendered before updating its styles,
      // 500ms is used just because it feels better if the banner takes half a second before it appears.
      setTimeout(() => {
        if (
          this.isRecipientPortal &&
          AccredibleResponsiveHelper.isScreenLessThan(AccredibleResponsiveBreakpoint.XS)
        ) {
          this._setTopForRecipientPortal();
          this._setupOnScrollForRecipientPortal();
        }
        this.cookiesBanner.nativeElement.style.visibility = 'unset';
      }, 500);
    }
  }

  ngOnDestroy(): void {
    this._matSideNavContentEl.removeEventListener('scroll', this._setTopForRecipientPortal);
  }

  dismiss(): void {
    this._browserStorage.set(COOKIES_ACCEPTED, 'true');
    this.showCookiesBanner = false;
  }

  /**
   * This method creates a scroll listener on Recipient Portal's scrollable container, the mat-sidenav-content.
   * Because in RP we are using the Angular Material <mat-sidenav-container>, what scroll's is mat-sidenav-content and not window.
   * On each event we update the position of the cookies banner in order for it to always be aligned with the header.
   */
  private _setupOnScrollForRecipientPortal(): void {
    const matSideNavContentEl = this._document.querySelector('mat-sidenav-content');
    matSideNavContentEl?.addEventListener('scroll', this._setTopForRecipientPortal.bind(this));
  }

  private _setTopForRecipientPortal(): void {
    this.cookiesBanner.nativeElement.style.top = this._calculateTopForRecipientPortal();
  }

  /**
   * Calculate the value that has to be added to top in order for the cookies banner to be aligned with the Recipient Portal header
   */
  private _calculateTopForRecipientPortal(): string {
    const mainContent = this._document.getElementById('main-content');
    const mainContentTop = (<HTMLElement>mainContent)?.getBoundingClientRect().top;
    return mainContentTop >= 0 ? mainContentTop + 'px' : '0px';
  }
}

import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { DefaultToastComponent } from './default-toast.component';

const MATERIAL_MODULES = [MatButtonModule, MatIconModule];

@NgModule({
  imports: [TranslocoModule, ...MATERIAL_MODULES],
  exports: [DefaultToastComponent],
  declarations: [DefaultToastComponent],
  providers: [],
})
export class DefaultToastComponentModule {}

import { AccredibleExternalLinkDirectiveModule } from '@accredible-frontend-v2/directives/external-link';
import { AccredibleButtonComponentModule } from '@accredible-frontend-v2/new-components/button';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { TranslocoModule } from '@ngneat/transloco';
import { CookiesBannerComponent } from './cookies-banner.component';

const MATERIAL_MODULES = [MatButtonModule];

@NgModule({
  imports: [
    CommonModule,
    AccredibleExternalLinkDirectiveModule,
    AccredibleButtonComponentModule,
    ...MATERIAL_MODULES,
    TranslocoModule,
  ],
  exports: [CookiesBannerComponent],
  declarations: [CookiesBannerComponent],
  providers: [],
})
export class AccredibleCookiesBannerComponentModule {}

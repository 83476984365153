export enum ActiveFeatureFlags {
  CREDENTIAL_FORM = 'credential_form',
  MFA_ISSUER = 'mfa_issuer',
  EMAIL_CAMPAIGNS = 'email_campaigns',
  EMAIL_CAMPAIGN_DELIVERY_CONDITION = 'email_campaign_delivery_condition',
  CANVAS_INTEGRATION_LTI_V1P3 = 'canvas_integration_lti_v1p3',
  BRIGHTSPACE_INTEGRATION_LTI_V1P3 = 'brightspace_integration_lti_v1p3',
  SKILLJAR_CUSTOM_ATTRIBUTES = 'skilljar_integration_custom_attributes',
}

export type FeatureFlag = {
  [K in ActiveFeatureFlags]: boolean;
};

import { EarningCriteria } from './earning-criteria.model';
import { AccredibleOrganizationIssuer } from './issuer.model';

export interface AccredibleGroup {
  id: number;
  name: string;
  course_name: string;
  course_description: string;
  course_link: string;
  language: string;
  created_at: string;
  updated_at: string;
  credentials_count: number;
  primary_design_id: number;
  has_certificate_design?: boolean;
  certificate_design: AccredibleDesign;
  has_badge_design?: boolean;
  badge_design: AccredibleDesign;
  learning_outcomes: string[];
  organization?: AccredibleOrganizationIssuer;
  course_link_show: boolean;
  signup_url: string;
  signup_url_show: boolean;
  organization_link_show: boolean;
  enrolment_count: number;
  attach_pdf: boolean;
  is_linkedin_education_section: boolean;
  expire: boolean;
  expired_on: Date;
  issuer_name: string;
  issuer_url: string;
  // TODO: Not sure about the type
  transcript: null;
  image_url?: string;
  transcript_viewable: boolean;
  spiderable: boolean;
  custom_message: string;
  custom_message_show: boolean;
  custom_message_recipient_only: boolean;
  show_job_insights: boolean;
  job_insights: AccredibleJobInsights;
  certificate_design_id?: number;
  badge_design_id?: number;
  tracked_course_link: string;
  earning_criteria: EarningCriteria[];
  physical_awards_enabled?: boolean;
}

export interface AccredibleDesign {
  id: number;
  name?: string;
  size: 'us-letter' | 'a4';
  orientation: 'landscape' | 'portrait' | null;
  blocks: AccredibleDesignBlock[];
  background: string;
  background_bleeds: boolean;
  rasterized_content_url: string;
  svg: string;
  kind: string;
  attribute_keys?: { [key: string]: AccredibleAttributeTypes };

  updated_at?: string; // Date
  custom_attributes?: any[];
  encoded_content?: string;
  groups?: any[];
}

export enum AccredibleAttributeTypes {
  DATE = 'date',
  DRAFT = 'draft',
  EMAIL = 'email',
  IMAGE = 'image',
  STRING = 'string',
  TEXT = 'text',
}

export interface AccredibleDesignBlock {
  top: string;
  left: string;
  font_size?: string;
  z_index?: number;
  font?: string;
  center?: boolean;
  bold?: boolean;
  model?: string;
  font_weight?: string;
  color?: string;
  underline?: boolean;
  image_url?: string;
  image_width?: string;
  image_height?: string;
  align?: string;
  italic?: boolean;
  line_width?: string;
  line_style?: string;
  vertical_align?: 'top' | 'center' | 'bottom';

  scale_to_fit?: boolean;
  orientation?: 'horizontal' | 'vertical';
  custom_image_attribute?: any;
  qr_code?: boolean;
  background_color?: string;
  foreground_color?: string;
  date_format?: string;

  pixel_width?: number;
  pixel_height?: number;
}

export interface AccredibleJobInsights {
  category: string;
  main_skill: string;
}

import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleLanguage } from '@accredible-frontend-v2/models';
import { SelectInputOptionWithDynamicLabel } from '@accredible-frontend-v2/new-components/select-input';
import { AccountsRedirectionKey } from '@accredible-frontend-v2/services/accounts-redirection';
import { AccredibleBrowserStorageService } from '@accredible-frontend-v2/services/browser-storage';
import { accredibleCustomThemesMetadata } from '@accredible-frontend-v2/utils/themes';
import { Direction } from '@angular/cdk/bidi';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslocoService } from '@ngneat/transloco';
import { HashMap } from '@ngneat/transloco/lib/types';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { AVAILABLE_LANGUAGES, getAvailableLanguages } from './languages';

@Injectable({
  providedIn: 'root',
})
export class AccredibleLanguageService {
  private readonly _languageCodeSubject$ = new BehaviorSubject<string>('en');
  languageCode$ = this._languageCodeSubject$.asObservable();
  private readonly _direction$ = new BehaviorSubject<Direction>('ltr');
  direction$ = this._direction$.asObservable();

  private _locale: string;
  private _direction: Direction;

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _transloco: TranslocoService,
    private readonly _browserStorage: AccredibleBrowserStorageService,
    private readonly _dateAdapter: DateAdapter<Date>,
  ) {}

  setLanguage(languageCode: string, themeName?: string): void {
    if (!languageCode) {
      // If a language is not passed in, use the language saved in local storage if exists
      if (this._browserStorage.get(AccountsRedirectionKey.LANGUAGE)) {
        languageCode = this._browserStorage.get(AccountsRedirectionKey.LANGUAGE);
      } else {
        // If a user never selected a language, use the language from the browser
        languageCode = navigator.language.toLowerCase();
      }
    }

    // Check if language is supported
    languageCode = this.getAvailableLanguageCode(languageCode, themeName);
    this._document.documentElement.setAttribute('lang', languageCode);

    this._locale = languageCode;
    this._dateAdapter.setLocale(languageCode);
    this._transloco.setActiveLang(languageCode);
    this._transloco
      .selectTranslate('language.direction')
      .pipe(take(1))
      .subscribe((direction) => {
        this._direction = direction;
        this._document.body.setAttribute('dir', direction);
        this._direction$.next(direction);
      });

    if (_.isEmpty(this._transloco.getTranslation(languageCode))) {
      // Language is not loaded, wait for it to load
      this._transloco.events$
        .pipe(first((event) => event.type === 'translationLoadSuccess'))
        .subscribe(() => {
          this._languageCodeSubject$.next(languageCode);
        });
    } else {
      // Language is loaded
      this._languageCodeSubject$.next(languageCode);
    }
  }

  getSelectedLanguage(): AccredibleLanguage {
    return AVAILABLE_LANGUAGES.filter((language) => {
      return language.code === this._locale;
    })[0];
  }

  getDirection(): Direction {
    return this._direction;
  }

  translate(key: string, params?: HashMap): string {
    return this._transloco.translate<string>(key, params);
  }

  selectTranslate(key: string, params?: HashMap): Observable<string> {
    return this._transloco.selectTranslate<string>(key, params);
  }

  /**
   * This method will return the language code if it exists,
   * else it will search for the most similar one,
   * else will default to en-us.
   */
  getAvailableLanguageCode(languageCode: string, themeName?: string): string {
    // Check if language exists
    let supportedLanguages;
    if (environment.theming) {
      // If previewing a theme we only support en language
      supportedLanguages = getAvailableLanguages(['en']);
    } else if (themeName) {
      // If app supports theming get theme available languages
      supportedLanguages = getAvailableLanguages(
        // <any> cast is used in order to avoid typescript strict error
        (<any>accredibleCustomThemesMetadata)[themeName].languages,
      );
    } else {
      // If themeName is undefined app should support all languages
      supportedLanguages = getAvailableLanguages();
    }

    let language = supportedLanguages.find((lang) => lang.code === languageCode);
    if (!language) {
      // If it doesn't exist,
      // Find the first that starts with the first two letters
      // If not, find english (prioritizing american because is before 'en-gb' on the list)
      languageCode = languageCode.split('-')[0];
      language =
        supportedLanguages.find((lang) => lang.code.startsWith(languageCode)) ||
        supportedLanguages.find((lang) => lang.code.startsWith('en'));
    }
    if (!language) {
      // If it still doesn't exist,
      // Default to the first supported language.
      languageCode = supportedLanguages[0].code;
    } else {
      // If it exists, assign it
      languageCode = language.code;
    }
    return languageCode;
  }

  // TODO(Fred): Instead of having a param translationKey, we should only have one source of truth for language translations
  getLanguagesSelectorTranslationsForForms(
    translationKey: string,
  ): SelectInputOptionWithDynamicLabel[] {
    return Object.values(AVAILABLE_LANGUAGES).map((language) => ({
      name: of(
        language.code.startsWith(this.getSelectedLanguage().code.slice(0, 2))
          ? `${this.translate(`${translationKey}.${language.code}`)}`
          : `${this.translate(`${translationKey}.${language.code}`)} - ${language.name}`,
      ),
      value: language.code,
    }));
  }
}

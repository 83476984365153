import { AccredibleMfaSettings } from './mfa.model';

export interface AccredibleUser {
  id: number;
  name: string;
  username: string;
  avatar: string;
  email: string;
  // TODO: `any` because, { [key: string]: string } type on social_media produces an error on the keyvalue pipe in credential.container.ts
  social_media: any;

  has_password: boolean;
  has_verified: boolean;
  restrict_to_single_issuer: boolean;
  is_pending_confirmation: boolean;
  is_confirmed: boolean;
  private: boolean;

  course_recommendations_enabled: boolean;
  eligible_spotlight_directories: UserEligibleSpotlightDirectory[];
  mfa_settings: AccredibleMfaSettings;
}

export interface UserEligibleSpotlightDirectory {
  visible: boolean;
  id: number;
  notified: boolean;
  user_setting_id: number;
}
